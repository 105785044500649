<template>
  <div>
    <b-form-group
      :label="label"
      cols="12"
    >
      <div class="d-flex col-input-select">
        <b-form-group
          :invalid-feedback="
            flagRecurrenceVoidValid == false
              ? $t('RequiredField')
              : flagRecurrenceNumberValid == false
              ? $t('IntegrationPage.ValidateValueRecurrence')
              : ''
          "
          :class="`m-0 p-0 ${classInput}`"
        >
          <b-form-input
            :placeholder="placeholder"
            :value="inputValue"
            :type="typeInput"
            :state="stateInvalidInput"
            :class="`input-comp-inputselect w-100`"
            @input="updateValue($event, 'input')"
            @keydown="typeInput === 'number' ? filterInput($event) : ''"
          />
        </b-form-group>
        <SingleSelect
          id="input-option"
          :class="`${classSelect} select-comp-inputselect p-0`"
          :placeholder="$t('Select')"
          :state="stateInvalidSelect"
          :optionSelected="selectValue"
          :options="selectOptions"
          @input="
            (value) => {
              selectValue = value;
              updateValue(value, 'select');
            }
          "
        >
          {{ $t('RequiredField') }}
        </SingleSelect>
      </div>
      <slot></slot>
    </b-form-group>
  </div>
</template>

<script>
  import { BFormGroup, BFormInput, BFormSelect } from 'bootstrap-vue';
  import SingleSelect from '@core/components/multiselect/SingleSelect.vue';

  export default {
    components: {
      BFormGroup,
      BFormInput,
      BFormSelect,
      SingleSelect
    },
    props: {
      label: {
        type: String,
        default: ''
      },
      classInput: {
        type: String,
        default: 'col-8'
      },
      classSelect: {
        type: String,
        default: 'col-4'
      },
      placeholder: {
        type: String,
        default: ''
      },
      selectOptions: {
        type: Array,
        default: []
      },
      inputValue: {
        default: ''
      },
      selectValue: {
        default: ''
      },
      typeInput: {
        type: String,
        default: 'text'
      },
      stateInvalidInput: {
        default: null
      },
      stateInvalidSelect: {
        default: null
      },
      invalidFeedback: {
        type: String,
        default: ''
      },
      flagRecurrenceVoidValid: {
        type: Boolean
      },
      flagRecurrenceNumberValid: {
        type: Boolean
      }
    },
    data() {
      return {
        controlRender: 0
      };
    },
    methods: {
      updateValue(value, type) {
        if (type == 'select') {
          this.$emit('update:selectValue', value);
        } else if (type == 'input') {
          this.$emit('update:inputValue', value);
        }
      },
      filterInput(event) {
        let input = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        let keyPressed = event.key;
        let keyCode = event.keyCode ? event.keyCode : event.which;
        if (!input.includes(keyPressed) && keyCode != 8) {
          event.preventDefault();
        }
      }
    }
  };
</script>

<style lang="scss">
  .input-comp-inputselect {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    margin: 0 !important;
    max-width: 100% !important;
  }
  .select2-selection.select2-selection--single {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  .select-comp-inputselect {
    .multiselect {
      .multiselect__content-wrapper {
        min-width: 100%;
        width: auto !important;
        right: 0;
      }

      &.multiselect-is-invalid {
        border: none !important;
        border-radius: 6px;
        .multiselect__tags {
          border: 1px solid #d32f2f !important;
        }

        .multiselect__select {
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.47162 3.05602L5.00158 7.5909L0.528469 3.05593L1.17644 2.40774L4.73416 6.02906L5.00178 6.30146L5.26928 6.02894L8.82368 2.40785L9.47162 3.05602Z' fill='%234C4541' stroke='%234C4541' stroke-width='0.75'/%3E%3C/svg%3E");
        }
      }
    }

    .multiselect__tags {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      margin: 0;
    }
    width: 100%;
    background: #fff url('../../../assets/images/icons/arrow-icon-dark.png') right 1rem center/10px
      10px no-repeat !important;
  }
</style>
