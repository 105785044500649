var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"card-activity-origin-destiny"},[_c('div',{staticClass:"container-card-header"},[_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.titleCard)+" ")]),_c('div',{staticClass:"container-buttons"},[_c('b-button',{staticClass:"btn-scheduling btn-clean",on:{"click":_vm.cleanRows}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('IntegrationPage.Clean'))+" ")])]),_c('b-button',{staticClass:"btn-scheduling btn-import",on:{"click":_vm.importSchemas}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('IntegrationPage.ImportSchemas'))+" ")])])],1)]),_c('b-col',{key:("list-rows-map-" + _vm.controlRender),staticClass:"p-0",attrs:{"cols":"12"}},_vm._l((_vm.rowList),function(item,index){return _c('b-col',{key:index,staticClass:"d-flex flex-column flex-sm-row col-12 container-rows p-0"},[_c('b-form-group',{staticClass:"col-12 col-sm-5",attrs:{"label":_vm.$t('IntegrationPage.Source')}},[_c('SingleSelect',{attrs:{"id":("input-origin-" + _vm.cardID + "-" + index),"placeholder":_vm.$t('Select'),"state":_vm.rowList[index].stateOrigin,"optionSelected":_vm.fieldsReturnOrigin.find(function (item) { return item.value == _vm.rowList[index].origin_id; }),"options":[
              _vm.rowList[index].origin_id
                ? _vm.fieldsReturnOrigin.find(function (item) { return item.value == _vm.rowList[index].origin_id; })
                : null ].concat( _vm.fieldsReturnOrigin.filter(
                function (item1) { return !_vm.rowList.map(function (item2) { return item2.origin_id; }).includes(item1.value); }
              )
            ).filter(function (item) { return item != null; })},on:{"input":function (value) {
              _vm.rowList[index].origin_id = value ? value.value : null;
              _vm.rowList[index].origin_name = value
                ? value.value
                  ? _vm.fieldsReturnOrigin.filter(function (item) { return item.value == _vm.rowList[index].origin_id; })[0]
                      .text
                  : null
                : null;
              _vm.rowList[index].origin_type = value
                ? value.value
                  ? _vm.fieldsReturnOrigin.filter(function (item) { return item.value == _vm.rowList[index].origin_id; })[0]
                      .type
                  : null
                : null;
            }}},[_vm._v(" "+_vm._s(_vm.$t('RequiredField'))+" ")])],1),_c('b-form-group',{staticClass:"col-12 col-sm-5",attrs:{"label":_vm.$t('IntegrationPage.Destination')}},[_c('SingleSelect',{attrs:{"id":("input-destiny-" + _vm.cardID + "-" + index),"placeholder":_vm.$t('Select'),"state":_vm.rowList[index].stateDestiny,"optionSelected":_vm.fieldsReturnDestiny.find(function (item) { return item.value == _vm.rowList[index].destiny_id; }),"options":[
              _vm.rowList[index].destiny_id
                ? _vm.fieldsReturnDestiny.find(function (item) { return item.value == _vm.rowList[index].destiny_id; })
                : null ].concat( _vm.fieldsReturnDestiny.filter(
                function (item1) { return !_vm.rowList.map(function (item2) { return item2.destiny_id; }).includes(item1.value); }
              )
            ).filter(function (item) { return item != null; })},on:{"input":function (value) {
              _vm.rowList[index].destiny_id = value ? value.value : null;
              _vm.rowList[index].destiny_name = value
                ? value.value
                  ? _vm.fieldsReturnDestiny.filter(
                      function (item) { return item.value == _vm.rowList[index].destiny_id; }
                    )[0].text
                  : null
                : null;
              _vm.rowList[index].destiny_type = value
                ? value.value
                  ? _vm.fieldsReturnDestiny.filter(
                      function (item) { return item.value == _vm.rowList[index].destiny_id; }
                    )[0].type
                  : null
                : null;
            }}},[_vm._v(" "+_vm._s(_vm.$t('RequiredField'))+" ")])],1),_c('b-col',{staticClass:"d-flex align-items-center container-buttons col-12 col-sm-2 p-sm-0"},[(_vm.rowList.length - 1 == index)?_c('b-button',{class:[
            'btn-activity btn-add',
            _vm.rowList[index].stateOrigin == false || _vm.rowList[index].stateDestiny
              ? 'btn-activity-invalid'
              : ''
          ],on:{"click":function($event){return _vm.newRow()}}},[_c('AddIcon')],1):_vm._e(),(_vm.rowList.length > 1)?_c('b-button',{class:[
            'btn-activity btn-remove',
            _vm.rowList[index].stateOrigin == false || _vm.rowList[index].stateDestiny
              ? 'btn-activity-invalid'
              : ''
          ],on:{"click":function($event){return _vm.deleteRow(index)}}},[_c('DeleteIcon')],1):_vm._e()],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }