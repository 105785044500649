<template>
  <b-card id="card-run-stored-procedure">
    <div class="container-card-header">
      <span class="title"> Stored Procedure </span>
    </div>

    <div cols="12">
      <b-row>
        <b-form-group
          class="col-12 col-sm-6"
          :label="$t('IntegrationPage.Connection')"
        >
          <SingleSelect
            id="input-procedure-connection"
            :placeholder="$t('Select')"
            :state="flagValidConnection"
            :optionSelected="selectedConnection"
            :options="sqlConnectionsList"
            @input="(value) => (selectedConnection = value)"
          >
            {{ $t('RequiredField') }}
          </SingleSelect>
        </b-form-group>

        <b-form-group
          class="col-12 col-sm-6"
          label="Procedure"
          :invalid-feedback="$t('RequiredField')"
        >
          <b-form-input
            id="input-procedure"
            :placeholder="$t('TypeHere')"
            v-model="selectedProcedure"
            :state="flagValidProcedure"
            @input="(value) => (selectedProcedure = value)"
          />
        </b-form-group>
      </b-row>
    </div>
  </b-card>
</template>

<script lang="js">
  import SingleSelect from '@core/components/multiselect/SingleSelect.vue';
  import { mapGetters, mapMutations } from 'vuex';
  import Ripple from 'vue-ripple-directive';
  import { BRow, BFormGroup, BFormInput, BCard, VBToggle } from 'bootstrap-vue';

  export default {
    components: {
      BRow,
      BFormGroup,
      BFormInput,
      BCard,
      SingleSelect
    },
    directives: {
      'b-toggle': VBToggle,
      Ripple
    },
    props: {
      connectionsList: {
        type: Array,
        default: null
      },
      sqlConnectionsList: {
        type: Array,
        default: []
      },
      editingData: {
        type: Object
      }
    },
    watch: {
      flagEditActivity(v) {
        if (v && this.flagRunProcedureAction) {
          this.editing = true;
          this.setDataForEditing();
        }
      },
      flagCleanFields(v) {
        if (v) this.cleanFields();
      },
      flagActivityButton(v) {
        if (v && this.flagRunProcedureAction) this.saveData();
      },
      flagRunProcedureAction(v) {
        if (!v) this.cleanFields();
      }
    },
    computed: {
      ...mapGetters('flags_controller_pipelines', [
        'flagActivityButton',
        'flagCleanFields',
        'flagEditActivity',
        'flagRunProcedureAction'
      ])
    },
    mounted() {
      if (this.flagEditActivity && this.flagRunProcedureAction) this.setDataForEditing();
    },
    data() {
      return {
        currentSite: JSON.parse(localStorage.getItem('currentSite')),
        selectedConnection: null,
        selectedProcedure: null,
        flagValidConnection: null,
        flagValidProcedure: null,
        proceduresList: [],
        editing: false
      };
    },
    methods: {
      ...mapMutations('flags_controller_pipelines', ['UPDATE_FLAG_RUN_PROCEDURE_VALIDATION']),
      saveData() {
        let validate = this.validateFields();

        if (validate) {
          let data = {
            connectionId: this.selectedConnection.value,
            procedureName: this.selectedProcedure
          };

          this.$emit('setValue', data);
        }
      },
      validateFields() {
        this.flagValidConnection = this.selectedConnection != null ? null : false;
        this.flagValidProcedure = this.selectedProcedure != null ? null : false;

        let validate =
          this.flagValidConnection == false || this.flagValidProcedure == false ? false : true;

        this.UPDATE_FLAG_RUN_PROCEDURE_VALIDATION(validate);

        return validate;
      },
      cleanFields() {
        this.selectedConnection = null;
        this.selectedProcedure = null;
        this.flagValidConnection = null;
        this.flagValidProcedure = null;
      },
      async setDataForEditing() {
        this.selectedConnection = this.sqlConnectionsList.find(
          (item) => item.value == this.editingData.activity.runStoredProcedure?.connectionId
        );
        this.selectedProcedure = this.editingData.activity.runStoredProcedure?.procedureName;

        this.editing = false;
      }
    }
  };
</script>

<style lang="scss">
  #card-run-stored-procedure {
    overflow-x: visible;

    .card-body {
      padding: 16px 16px 0px 16px;
    }

    input::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #cfc4be;
    }

    label,
    legend {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 0 !important;
      padding-bottom: 4px !important;
    }

    .invalid-feedback {
      font-weight: 300;
      font-size: 10px;
      line-height: 14px;
      color: #d32f2f;
      text-align: left;
    }

    .container-card-header {
      flex-direction: row;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #4c4541;
      }
      .title-list {
        margin: 16px 0;
      }
    }
  }
</style>
