var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{attrs:{"label":_vm.label,"cols":"12"}},[_c('div',{staticClass:"d-flex col-input-select"},[_c('b-form-group',{class:("m-0 p-0 " + _vm.classInput),attrs:{"invalid-feedback":_vm.flagRecurrenceVoidValid == false
            ? _vm.$t('RequiredField')
            : _vm.flagRecurrenceNumberValid == false
            ? _vm.$t('IntegrationPage.ValidateValueRecurrence')
            : ''}},[_c('b-form-input',{class:"input-comp-inputselect w-100",attrs:{"placeholder":_vm.placeholder,"value":_vm.inputValue,"type":_vm.typeInput,"state":_vm.stateInvalidInput},on:{"input":function($event){return _vm.updateValue($event, 'input')},"keydown":function($event){_vm.typeInput === 'number' ? _vm.filterInput($event) : ''}}})],1),_c('SingleSelect',{class:(_vm.classSelect + " select-comp-inputselect p-0"),attrs:{"id":"input-option","placeholder":_vm.$t('Select'),"state":_vm.stateInvalidSelect,"optionSelected":_vm.selectValue,"options":_vm.selectOptions},on:{"input":function (value) {
            _vm.selectValue = value;
            _vm.updateValue(value, 'select');
          }}},[_vm._v(" "+_vm._s(_vm.$t('RequiredField'))+" ")])],1),_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }