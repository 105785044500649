var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"id":"card-activity-integration"}},[_c('div',{staticClass:"container-card-header"},[_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('IntegrationPage.Integration'))+" ")])]),_c('div',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-form-group',{staticClass:"col-12 col-sm-6",attrs:{"label":_vm.$t('IntegrationPage.SourceConnection')}},[_c('SingleSelect',{attrs:{"id":"input-origin-connection","placeholder":_vm.$t('Select'),"state":_vm.flagValidOriginConnection,"optionSelected":_vm.selectedOriginConnection,"options":_vm.connectionsListOrigin},on:{"input":function (value) {
              _vm.selectedOriginConnection = value;
              _vm.selectedOriginConnection != null &&
                _vm.filterObjects(_vm.selectedOriginConnection.value, 'origin');
            }}},[_vm._v(" "+_vm._s(_vm.$t('RequiredField'))+" ")])],1),_c('b-form-group',{staticClass:"col-12 col-sm-6",attrs:{"label":_vm.$t('IntegrationPage.SourceObject'),"invalid-feedback":_vm.flagActivityEqual ? _vm.$t('NameAlreadyExists') : _vm.$t('RequiredField')}},[_c('SingleSelect',{attrs:{"id":"input-origin-object","placeholder":_vm.$t('Select'),"state":_vm.flagValidOriginObject,"disabled":_vm.selectedOriginConnection == null || _vm.selectedOriginConnection == '',"optionSelected":_vm.selectedOriginObject,"options":_vm.objectsOriginList},on:{"input":function (value) {
              _vm.selectedOriginObject = value;
              _vm.selectedOriginObject != null && _vm.filterFields(_vm.selectedOriginObject.value, 'origin');
            }}},[_vm._v(" "+_vm._s(_vm.$t('RequiredField'))+" ")])],1)],1),_c('b-row',[_c('b-form-group',{staticClass:"col-12 col-sm-6",attrs:{"label":_vm.$t('IntegrationPage.DestinationConnection')}},[_c('SingleSelect',{attrs:{"id":"input-destiny-connection","placeholder":_vm.$t('Select'),"state":_vm.flagValidDestinyConnection,"optionSelected":_vm.selectedDestinyConnection,"options":_vm.connectionsListDestiny},on:{"input":function (value) {
              _vm.selectedDestinyConnection = value;
              _vm.selectedDestinyConnection != null &&
                _vm.filterObjects(_vm.selectedDestinyConnection.value, 'destiny');
            }}},[_vm._v(" "+_vm._s(_vm.$t('RequiredField'))+" ")])],1),_c('b-form-group',{staticClass:"col-12 col-sm-6",attrs:{"label":_vm.$t('IntegrationPage.DestinationObject'),"invalid-feedback":_vm.flagActivityEqual ? _vm.$t('RefuseStatus.NameAlreadyExists') : _vm.$t('RequiredField')}},[_c('SingleSelect',{attrs:{"id":"input-destiny-object","placeholder":_vm.$t('Select'),"state":_vm.flagValidDestinyObject,"disabled":_vm.selectedDestinyConnection == null || _vm.selectedDestinyConnection == '',"optionSelected":_vm.selectedDestinyObject,"options":_vm.objectsDestinyList},on:{"input":function (value) {
              _vm.selectedDestinyObject = value;
              _vm.selectedDestinyObject != null &&
                _vm.filterFields(_vm.selectedDestinyObject.value, 'destiny');
            }}},[_vm._v(" "+_vm._s(_vm.$t('RequiredField'))+" ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }