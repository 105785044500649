<template>
  <b-form-group
    :label="label"
    :label-for="labelFor"
    class="vue-select-modified select-add"
  >
    <v-select
      :placeholder="placeholder"
      v-model="selecteds"
      :options="options"
      :state="stateField"
      :class="`vue-select-multiple-component ${stateField == false ? 'is-invalid-vue-select' : ''}`"
      :readonly="false"
      :deselectFromDropdown="true"
    >
      <template #search="{ attributes, events }">
        <input
          class="vs__search input-multi-select-tk"
          id="input-search-select"
          v-bind="attributes"
          v-on="events"
          v-model="search"
          @focus="removeReadonly"
          @blur="atualizaListagem"
        />
      </template>

      <template #open-indicator="{}">
        <span>
          <ArrowDownIcon />
        </span>
      </template>
    </v-select>
    <!-- invalid feedback -->
    <slot></slot>
  </b-form-group>
</template>

<script>
  import Ripple from 'vue-ripple-directive';

  import { BFormGroup } from 'bootstrap-vue';
  import vSelect from 'vue-select';
  import ArrowDownIcon from '@/assets/images/pages/arrow-down-dark.svg';

  export default {
    components: {
      BFormGroup,
      vSelect,
      ArrowDownIcon
    },
    directives: {
      Ripple
    },
    props: {
      label: {
        default: ''
      },
      labelFor: {
        default: ''
      },
      placeholder: {
        default: ''
      },
      valueSelect: {
        default: ''
      },
      options: {
        type: Array,
        default: []
      },
      stateField: {
        default: ''
      },
      enableSelectAll: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        flagSelectResume: false,
        selecteds: null,
        componentCreated: true,
        search: ''
      };
    },
    methods: {
      atualizaListagem() {
        let validations = this.options.map((item) => {
          return item.value == this.search ? true : false;
        });

        if (this.search != '') {
          if (validations.includes(false)) {
            this.valueSelect = { label: this.search, value: this.search };
            this.search = '';
          }

          if (this.options.length == 0) {
            this.$emit('valueInput', { value: this.search, label: this.search });
            this.search = '';
          }
        }

        if (this.valueSelect?.value) this.search = '';
      },
      controlChips() {
        this.countChip = 0;
        this.flagSelectResume = false;

        let vSelect = this.$children[0].$children[0].$el.children[0].children[0];
        let chips = vSelect.getElementsByTagName('span');
        let chipResume = vSelect.getElementsByClassName('select-resume')[0];
        let vSelectWidth = vSelect.offsetWidth;

        let sum = 0;

        for (let chip of chips) {
          sum += chip.offsetWidth;
          if (sum + 80 < vSelectWidth * 0.85 && chip != chipResume) {
            this.countChip += 1;
            this.flagSelectResume = false;
          } else {
            this.flagSelectResume = true;
          }
        }
      },
      removeReadonly() {
        let nodes = document.querySelectorAll('.input-multi-select-tk');

        for (let elem of nodes) {
          elem.removeAttribute('readonly');
        }

        // if(this.valueSelect.value) this.search = this.valueSelect.value
      },
      onBlur() {
        let nodes = document.querySelectorAll('.input-multi-select-tk');

        for (let elem of nodes) {
          elem.blur();
        }

        this.search = '';
      }
    },
    updated() {
      this.controlChips();
    },
    watch: {
      selecteds(v) {
        this.$emit('update:valueSelect', v);
        this.onBlur();
      },
      valueSelect(v) {
        this.selecteds = v;
        this.componentCreated = false;
      }
    }
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .select-add {
    .vs__selected {
      padding: 0 !important;
    }
    .vs__clear {
      display: none;
    }
  }

  #vs2__listbox {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px !important; /* width of the entire scrollbar */
      border-radius: 50%;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fbeee8;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #cfc4be; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: 10px solid transparent;
    }
  }
  .vue-select-modified {
    .is-invalid-vue-select {
      .vs__dropdown-toggle {
        border-color: #ea5455;
      }
    }

    .form-control.is-invalid {
      background-image: none !important;
    }

    .vue-close-select {
      svg path {
        fill: #fff !important;
      }
    }

    .vs__actions {
      padding-top: 0 !important;
      svg {
        margin-right: 14px;
        height: 6.25px;
        width: 10px;
      }
    }
  }
</style>
