<template>
  <b-card id="card-linked-activity">
    <div class="container-card-header row">
      <b-col class="col-12 col-sm-6 order-1">
        <span class="title">
          {{ $t('IntegrationPage.LinkedActivities') }}
        </span>
      </b-col>

      <b-col class="col-12 col-sm-6 d-flex justify-content-sm-end order-3 order-sm-2">
        <div class="container-buttons">
          <b-button
            class="btn-activity btn-linked-activities"
            @click="newActivityEvent"
          >
            <span>
              {{ $t('IntegrationPage.NewActivity') }}
            </span>
          </b-button>
        </div>
      </b-col>

      <b-col class="col-12 order-2 order-sm-3">
        <h6 class="linked-activity-subtitle">
          {{ $t('IntegrationPage.DragGroup') }}
        </h6>
      </b-col>
    </div>

    <b-col
      cols="12"
      class="p-0"
    >
      <draggable
        tag="span"
        v-model="linkedActivityListLocal"
        :move="onMove"
        v-bind="dragOptions"
        :disabled="flagDisableDrag"
        handle=".draggable-item"
      >
        <transition-group>
          <CardLinkedActivity
            v-for="(item, index) in linkedActivityListLocal"
            :key="`card-activity-${item.order}`"
            :cardIndex="index"
            :data="item"
            :deleteActivityOrActivityGroup="deleteActivityOrActivityGroup"
            :linkedActivityList="linkedActivityListLocal"
            @changeCase="(value) => $emit('changeCase', value)"
            @setActivityForEditing="(value) => $emit('setActivityForEditing', value)"
            @setGroupForAddActivity="(value) => setGroupForAddActivity(value)"
            @disableDrag="(value) => (flagDisableDrag = value)"
          />
        </transition-group>
      </draggable>

      <div
        class="not-found-link-act"
        v-if="linkedActivityList.length == 0"
      >
        <span>
          <SearchIcon />
          <h3>{{ $t('Operator.NoResultsFound') }}</h3>
        </span>
      </div>
    </b-col>
  </b-card>
</template>

<script lang="js">
  import CardLinkedActivity from '@/views/pages/integration/pipelines/activity-session/CardLinkedActivity.vue';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import SearchIcon from '@/assets/images/pages/Search.svg';
  import { mapGetters, mapMutations } from 'vuex';
  import Ripple from 'vue-ripple-directive';
  import { BRow, BCard, VBToggle, BButton, BCol } from 'bootstrap-vue';
  import draggable from 'vuedraggable';
  export default {
    components: {
      BRow,
      BCard,
      BButton,
      BCol,
      CardLinkedActivity,
      SearchIcon,
      ToastificationContent,
      draggable
    },
    directives: {
      'b-toggle': VBToggle,
      Ripple
    },
    props: {
      pressButton: {
        type: Boolean
      },
      linkedActivityList: {
        type: Array,
        default: []
      },
      linkedActivityListOfRoute: {
        type: Array,
        default: []
      }
    },
    watch: {
      selectedAction(v) {
        let options = this.setActionOptions();
        let optionLabel = options.find((item) => item.value == v).text;
        this.showIBoxs(optionLabel);
      },
      linkedActivityList(v) {
        if (v != this.linkedActivityListLocal) {
          this.linkedActivityListLocal = [...v];
        }
      },
      linkedActivityListLocal(v) {
        this.linkedActivityListLocalBackup = [...v];
        this.$emit('updateLinkedActivityList', v);
      },
      linkedActivityListLocalBackup(newValue, oldValue) {
        const hasCreatedGroupByFront = oldValue?.find((item) => item.id.includes('-'));

        if (
          newValue.length == oldValue.length &&
          oldValue.length > 0 &&
          hasCreatedGroupByFront == undefined &&
          ((newValue[0]?.groupName != this.linkedActivityListLocalOld[0]?.groupName &&
            this.linkedActivityListLocalOld?.length > 0) ||
            (newValue[0]?.groupName != oldValue[0]?.groupName &&
              this.linkedActivityListLocalOld.length == 0))
        ) {
          if (
            newValue.length == oldValue.length &&
            oldValue.length > 0 &&
            newValue[0]?.groupName != oldValue[0]?.groupName
          ) {
            this.checkLinkedActivityListLocalSequence(newValue, oldValue);
          }
        } else if (newValue.length < oldValue.length && newValue.length > 0) {
          this.checkLinkedActivityListLocalDeleted(newValue, oldValue);
        }
      },
      flagConfirmChangeOrderLinkedActivityList(v) {
        if (v == false) {
          this.flagEnableModal = false;
          this.linkedActivityListLocal = [...this.linkedActivityListLocalOld];
        }

        if (v == null) {
          this.linkedActivityListLocalOld = [];
        }
      }
    },
    computed: {
      ...mapGetters('flags_controller_pipelines', [
        'flagFloatSaveButton',
        'selectedPerformedVia',
        'selectedEvent',
        'flagConfirmChangeOrderLinkedActivityList'
      ])
    },
    data() {
      return {
        currentSite: JSON.parse(localStorage.getItem('currentSite')),
        selectedActivityGroup: null,
        activitiyGroupOptions: [],
        activityInput: null,
        selectedAction: null,
        flagValidActivitiesGroup: null,
        flagActivityEqual: null,
        flagValidActivity: null,
        linkedActivityListLocal: [],
        linkedActivityListLocalBackup: [],
        flagValidAction: null,
        linkedActivityListLocalOld: [],
        dragOptions: {
          animation: 200,
          group: 'description',
          disabled: false,
          ghostClass: 'ghost'
        },
        flagDisableDrag: false,
        flagEnableModal: false
      };
    },
    mounted() {
      if (this.linkedActivityList.length > 0) this.$emit('hasData');
    },
    methods: {
      ...mapMutations('flags_controller_pipelines', [
        'UPDATE_FLAG_CONFIRM_CHANGE_ORDER_LINKED_ACTIVITY_LIST',
        'UPDATE_FLAG_LINKED_ACTIVITY_LIST_DELETED'
      ]),
      setActionOptions() {
        let optionsList = [
          { value: 1, text: this.$t('IntegrationPage.RunIntegration') },
          { value: 2, text: this.$t('IntegrationPage.SendIntegrationReport') }
        ];

        return optionsList;
      },
      onMove({ relatedContext, draggedContext }) {
        const relatedElement = relatedContext.element;
        const draggedElement = draggedContext.element;

        return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed;
      },
      newActivityEvent() {
        this.$emit('showIBox', 'new-activity');
      },
      showIBoxs(value) {
        this.$emit('showIBoxs', value);
      },
      validateFields() {
        this.flagValidActivitiesGroup = this.selectedActivityGroup != null ? null : false;
        this.flagValidActivity = this.activityInput != null ? null : false;
        this.flagValidAction = this.selectedAction != null ? null : false;

        let validate =
          this.flagValidActivitiesGroup == false ||
          this.flagValidActivity == false ||
          this.flagValidAction == false
            ? false
            : true;
        this.$emit('validateIBox', validate);
      },
      deleteActivityOrActivityGroup(groupID, type, activityID) {
        this.$swal({
          title: this.$t('IntegrationPage.attention'),
          text: this.$t(
            type === 'activity'
              ? 'IntegrationPage.DeleteAlertActivity'
              : type === 'activity-group'
              ? 'IntegrationPage.DeleteAlertActivityGroup'
              : ''
          ),
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonText: this.$t('MySites.cancel'),
          confirmButtonText: this.$t('IntegrationPage.Delete2'),
          customClass: {
            container: 'swal-scheduling',
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then((result) => {
          if (result.value) {
            this.$emit('deleteActivityOrActivityGroup', {
              groupID: groupID,
              type: type,
              activityID: activityID
            });
            this.showToast(
              this.$t('IntegrationPage.success'),
              'SmileIcon',
              this.$t(
                type === 'activity'
                  ? 'IntegrationPage.DeleteToastActivity'
                  : type === 'activity-group'
                  ? 'IntegrationPage.DeleteToastActivityGroup'
                  : ''
              ),
              'success'
            );
          }
        });
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      setGroupForAddActivity(value) {
        this.$emit('setGroupForAddActivity', value);
        this.$emit('setFlagNewAcitvityByGroup');
        this.$emit('showIBox', 'new-activity');
      },
      checkLinkedActivityListLocalSequence(newValue, oldValue) {
        if (this.selectedPerformedVia == 1 && this.selectedEvent >= 1 && this.selectedEvent <= 6) {
          if (oldValue.length != 0 && newValue.length > 1 && oldValue.length == newValue.length) {
            if (newValue[0]?.groupName != oldValue[0]?.groupName) {
              if (
                oldValue[0].activities.length == 1 &&
                (oldValue[0].activities[0].infos.action == 1 ||
                  oldValue[0].activities[0].infos.action == 2)
              ) {
                this.UPDATE_FLAG_CONFIRM_CHANGE_ORDER_LINKED_ACTIVITY_LIST(true);
                this.linkedActivityListLocalOld = [...oldValue];
              }
            }
          }
        }
      },
      checkLinkedActivityListLocalDeleted(newValue, oldValue) {
        if (this.selectedPerformedVia == 1 && this.selectedEvent >= 1 && this.selectedEvent <= 6) {
          if (newValue[0]?.groupName != oldValue[0]?.groupName) {
            if (
              oldValue[0].activities.length == 1 &&
              (oldValue[0].activities[0].infos.action == 1 ||
                oldValue[0].activities[0].infos.action == 2)
            ) {
              this.UPDATE_FLAG_LINKED_ACTIVITY_LIST_DELETED(true);
            }
          }
        }
      }
    }
  };
</script>

<style lang="scss">
  #card-linked-activity {
    .smooth-dnd-draggable-wrapper {
      overflow: visible !important;
    }

    .ghost {
      opacity: 0.5;
      background: #c8ebfb;
    }
    .card-body {
      padding: 16px 16px 0 16px !important;
    }

    input::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #cfc4be;
    }

    label,
    legend {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 0 !important;
      padding-bottom: 4px !important;
    }

    .invalid-feedback {
      font-weight: 300;
      font-size: 10px;
      line-height: 14px;
      color: #d32f2f;
      text-align: left;
    }

    .not-found-link-act {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 66px;
      border: 1px solid #cfc4be;
      margin: 16px 0;
      border-radius: 5px;
      width: 100%;
      background: #fafafa;
      span {
        display: flex;
        align-items: center;

        h3 {
          text-align: center;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #998f8a;
          margin-bottom: 0;
        }

        svg {
          margin-right: 16.13px;
        }
      }
    }

    .container-card-header {
      flex-direction: row;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #4c4541;
      }
      .title-list {
        margin: 16px 0;
      }
      .container-buttons {
        display: flex;
        .btn-activity {
          display: flex;
          padding: 0 !important;
          border-radius: 5px;
          border: 1px solid transparent !important;
          margin-left: 16px;
          span {
            width: 100%;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            color: #ffffff;
            letter-spacing: 0.0125em;
            padding: 4px 14px;
          }
          &:hover {
            box-shadow: none;
            background: #9f5714 !important;
          }
          &:active {
            background: #a45f1f !important;
          }
        }
        .btn-linked-activities {
          background: #fff !important;
          border: 1px solid #974900 !important;
          span {
            color: #974900;
          }
          &:hover {
            box-shadow: none;
            background: #ffede2 !important;
          }
          &:active {
            background: #ffdbc4 !important;
          }
        }
      }
    }

    .linked-activity-subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #998f8a;
      margin: 0;
    }
  }

  @media (max-width: 480px) {
    #card-linked-activity {
      .container-card-header {
        flex-direction: column;
        .title {
          align-self: flex-start;
        }
        .linked-activity-subtitle {
          margin-top: 4px;
        }
        .container-buttons {
          flex-direction: column;
          width: 100%;
          margin-top: 16px;
          .btn-activity {
            width: 100%;
            margin-left: 0 !important;
          }
        }
      }

      .not-found-link-act {
        height: 166px;
      }
    }
  }
</style>
