<template>
  <div>
    <b-col>
      <div class="procedure-item d-flex justify-content-between align-items-center">
        <div class="text-left d-flex flex-column mb-1 mb-sm-0 item-box">
          <span class="title-item">
            {{ $t('IntegrationPage.NameField') }}
          </span>
          <span
            class="text-item name-field"
            :id="`tooltip-name-field-${data.id}`"
          >
            {{ data.name }}
          </span>
          <b-tooltip
            custom-class="tooltip-new-pipeline"
            :target="`tooltip-name-field-${data.id}`"
            triggers="hover blur"
            positioning="top"
            placement="top"
            boundary-padding="0"
            class="tooltip-name-field"
          >
            <div class="info-tooltip-new-pipeline">
              {{ data.name }}
            </div>
          </b-tooltip>
        </div>
        <div class="text-left d-flex flex-column mb-1 mb-sm-0 item-box">
          <span class="title-item">
            {{ $t('IntegrationPage.Type') }}
          </span>
          <span class="text-item">
            {{
              data.type == 'after'
                ? this.$t('IntegrationPage.After')
                : data.type == 'before'
                ? this.$t('IntegrationPage.Before')
                : ''
            }}
          </span>
        </div>
        <div class="text-left d-flex flex-column mb-1 mb-sm-0 item-box">
          <span class="title-item">
            {{ $t('IntegrationPage.Local') }}
          </span>
          <span class="text-item">
            {{
              data.local == 'origem'
                ? this.$t('IntegrationPage.Source')
                : data.local == 'destino'
                ? this.$t('IntegrationPage.Destination')
                : ''
            }}
          </span>
        </div>

        <div class="d-flex justify-content-end item-box">
          <div class="container-btn-procedure-item d-none d-sm-flex">
            <b-button
              :id="`tooltip-btn-edit-${data.id}`"
              class="btn-activity btn-edit"
              @click="$emit('setEditingData', data)"
            >
              <span>
                <EditIcon />
              </span>
            </b-button>
            <b-tooltip
              custom-class="tooltip-new-pipeline"
              :target="`tooltip-btn-edit-${data.id}`"
              triggers="hover blur"
              positioning="top"
              placement="top"
              boundary-padding="0"
            >
              <div class="info-tooltip-new-pipeline">
                {{ $t('IntegrationPage.Edit') }}
              </div>
            </b-tooltip>

            <b-button
              :id="`tooltip-btn-delete-${data.id}`"
              class="btn-activity btn-delete"
              @click="deleteScheduling(data.id, arrayName)"
            >
              <span>
                <DeleteIcon />
              </span>
            </b-button>
            <b-tooltip
              custom-class="tooltip-new-pipeline"
              :target="`tooltip-btn-delete-${data.id}`"
              triggers="hover blur"
              positioning="top"
              placement="top"
              boundary-padding="0"
            >
              <div class="info-tooltip-new-pipeline">
                {{ $t('IntegrationPage.Delete2') }}
              </div>
            </b-tooltip>
          </div>
          <div class="d-block d-sm-none btn-drop-activity">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                class="d-action-activity"
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="$emit('setEditingData', data)">
                  <span class="icon-action-acitivity">
                    <EditDropdownIcon />
                  </span>
                  <span class="text-action-pipeline">
                    {{ $t('IntegrationPage.Edit') }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteScheduling(data.id, arrayName)">
                  <span class="icon-action-acitivity">
                    <DeleteDropdownIcon />
                  </span>
                  <span class="text-action-pipeline">
                    {{ $t('IntegrationPage.Delete2') }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </div>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script lang="js">
  import Ripple from 'vue-ripple-directive';
  import CloseIcon from '@/assets/images/icons/close-icon-small.svg';
  import DeleteIcon from '@/assets/images/icons/delete-icon-small.svg';
  import EditIcon from '@/assets/images/icons/edit-icon-small.svg';
  import LinkIcon from '@/assets/images/icons/link-icon.svg';
  import DeleteDropdownIcon from '@/assets/images/icons/trash-icon-2.svg';
  import EditDropdownIcon from '@/assets/images/pages/edit-icon-grey.svg';
  import LinkDropdownIcon from '@/assets/images/icons/link-2-icon.svg';
  import CloseDropdownIcon from '@/assets/images/icons/close-2-icon.svg';
  import { BRow, BTooltip, VBToggle, BButton, BDropdown, BDropdownItem } from 'bootstrap-vue';
  import { object } from '@ucast/core';

  export default {
    components: {
      BRow,
      BButton,
      BDropdown,
      BDropdownItem,
      BTooltip,
      CloseIcon,
      DeleteIcon,
      EditIcon,
      LinkIcon,
      DeleteDropdownIcon,
      EditDropdownIcon,
      LinkDropdownIcon,
      CloseDropdownIcon
    },
    directives: {
      'b-toggle': VBToggle,
      Ripple
    },
    data() {
      return {
        currentSite: JSON.parse(localStorage.getItem('currentSite')),
        flagSchedulingList: false,
        flagNewScheduling: false
      };
    },
    props: {
      data: {
        type: object,
        default: null
      },
      isLinked: {
        type: Boolean,
        default: false
      },
      showInfos: {
        type: Function
      },
      deleteScheduling: {
        type: Function
      },
      removeLinkedScheduling: {
        type: Function
      },
      linkScheduling: {
        type: Function
      },
      arrayName: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style lang="scss">
  .tootlip-inner {
    max-width: 400px;
  }

  .procedure-item {
    padding: 15px;
    border-radius: 6px;
    margin-bottom: 16px;
    background: #fafafa;
    border: 1px solid #cfc4be;

    .name-field {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .item-box {
      width: 15%;
      padding: 0 4px;
    }
    .item-box:nth-child(1) {
      width: 55%;
    }
    .item-box:nth-child(4) {
      width: 15%;
    }
    .title-item {
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      color: #998f8a;
    }

    .text-item {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #4c4541;
    }

    .btn-activity {
      background: none !important;
      border: none !important;
      padding: 0;
      width: 32px;
      height: 28px;
      margin-left: 8px;
      &:hover {
        box-shadow: none !important;
        background-color: #ffdbc4 !important;
      }
    }

    .d-action-activity {
      button {
        width: 32px;
        height: 28px;
        padding: 0;
        svg circle {
          color: #974900 !important;
        }
      }

      .dropdown-item {
        &:hover {
          background-color: #fbeee8;
          svg path {
            fill: #974900 !important;
          }
          span {
            color: #974900 !important;
          }
        }
      }

      button:hover {
        background-color: #ffdbc4 !important;
      }

      svg {
        margin: 0 !important;
      }

      .icon-action-acitivity {
        svg {
          height: 16px;
          width: 16px;
          path {
            fill: #998f8a;
          }
        }

        margin-right: 9px;
      }
    }
  }

  @media (max-width: 480px) {
    .tootlip-inner {
      max-width: 230px;
    }
    .procedure-item {
      flex-direction: column;
      justify-content: start !important;
      align-items: flex-start !important;
      position: relative;

      .d-link-sch {
        display: none;
      }

      .item-box:nth-child(1),
      .item-box:nth-child(2),
      .item-box:nth-child(3) {
        width: 85%;
      }
      .btn-drop-activity {
        position: absolute;
        top: 17.5px;
        right: 20.87px;
      }
    }
  }

  @media (max-width: 768px) {
    .tootlip-inner {
      max-width: 250px;
    }
    .procedure-item {
      .item-box {
        width: 25%;
      }
      .item-box:nth-child(5) {
        width: 0%;
      }
    }
  }
</style>
